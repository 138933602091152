import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../../components/contact-info/contact-info";
import SEO from "../../../components/seo/seo";
import VimeoVideoFrame from "../../../components/custom-widgets/vimeo-video-frame";
import TwoColumnSectionWithImage from "../../../components/custom-widgets/two-column-section-with-image";
import Button from "../../../components/custom-widgets/button";
import List from "../../../components/custom-widgets/list";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";

import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import showMktoForm from "../../../helpers/showMktoForm";

const TreasuryPrime = () => {
  const title = "WaFd Bank Treasury Prime - Treasury Management Services";
  const description =
    "Discover WaFd Bank Treasury Prime, our robust commercial online banking platform for your treasury management services to fit your business needs.";
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/prime/hero-treasury-prime-012623-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/prime/hero-treasury-prime-012623-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/prime/hero-treasury-prime-012623-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/prime/hero-treasury-prime-012623-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/prime/hero-treasury-prime-012623-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/prime/hero-treasury-prime-012623-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/prime/hero-treasury-prime-012623-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
      dashboardImage: file(relativePath: { eq: "third-party/treasury-prime-dashboard.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/treasury-management-services/prime"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-treasury-prime-012623.jpg"
      }
    ]
  };
  const heroChevronData = {
    id: "WAFD-Treasury-Prime-hero",
    ...getHeroImgVariables(imgData),
    altText: "Businesswoman in her office laughing.",
    chevronBgClass: "bg-info",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            text: "Seamless Integration for a Seamless Experience",
            class: "text-white"
          }
        },
        {
          id: 3,
          button: {
            type: "button",
            id: "treasury-prime-hero-cta",
            class: "btn-white",
            text: "Contact Us",
            onClick: () => showMktoForm(1067)
          }
        }
      ]
    }
  };
  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      url: "/commercial-banking/treasury-management-services",
      title: "Treasury Management"
    },
    {
      id: 3,
      active: true,
      title: "Prime"
    }
  ];

  const treasuryPreviewVideoData = {
    id: "WAFD-treasury-prime-demo-video",
    vimeoId: "394561595",
    title: "WAFD Treasury Prime Demo",
    class: "m-auto iframe w-100 border-radius-12"
  };

  const BenefitsContent = () => {
    return (
      <>
        <h2>Benefits of Treasury Prime</h2>
        <h5 className="mb-0 text-success font-weight-bold">New Products</h5>
        <p>
          Initiate a full suite of commercial lending products through a simple application that is sent directly to
          your banker.
        </p>
        <h5 className="mb-0 text-success font-weight-bold">Document Transfer</h5>
        <p>
          Securely deliver documents to your banker from your desktop using a simple "drag and drop" to get them in the
          right place.
        </p>
        <h5 className="mb-0 text-success font-weight-bold">Convenient Compliance</h5>
        <p>
          As your banker ensures the continuing health of your company, they will request financial statements and tax
          returns securely through the Connect portal. Simply "drag and drop" and get on with your day.
        </p>
        <Button
          id={"benefits-cta-contact-us"}
          class={"btn-primary no-min-width w-100 w-sm-auto"}
          onClick={() => showMktoForm(1067)}
          showIcon={false}
          type={"button"}
          text={"Contact Us"}
        />
      </>
    );
  };

  const clientConnectListData = {
    accountName: "treasury-prime-experience-list",
    items: [
      {
        id: 1,
        text: "Uses existing logins so you don't have to use multiple usernames and passwords"
      },
      {
        id: 2,
        text: "Simple navigation and tools that will guide you along the way"
      },
      {
        id: 3,
        text: "Safe and secure file transfer"
      },
      {
        id: 4,
        text: "Expand your business opportunities with us"
      }
    ]
  };

  const ClientConnectContent = () => {
    return (
      <>
        <h2>The WAFD Client Connect Advantage</h2>
        <h4 className="text-success font-weight-semi-bold">
          A new, easy way to do business with WAFD and your banker:
        </h4>
        <List {...clientConnectListData} />
        <Button
          id={"client-connect-cta-contact-us"}
          class={"btn-primary no-min-width w-100 w-sm-auto"}
          onClick={() => showMktoForm(1067)}
          showIcon={false}
          type={"button"}
          text={"Contact Us"}
        />
      </>
    );
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>WAFD Treasury Prime</h1>
        <h3 className="mb-0">
          WAFD Treasury Prime is a robust commercial online banking platform that incorporates each of the following
          into one online solution, with a personalized and easy to use experience.
        </h3>
      </section>
      <TwoColumnSectionWithImage isGray={true} contentOnLeft={false}>
        {{
          image: (
            <StaticImage
              src="../../../images/thumbnail-treasury-prime-030724.jpg"
              alt="A man working on his laptop smiling over his shoulder."
              placeholder="blurred"
              quality="100"
              className="border-radius-12 mb-3"
            />
          ),
          content: <BenefitsContent />
        }}
      </TwoColumnSectionWithImage>
      <TwoColumnSectionWithImage>
        {{
          image: <VimeoVideoFrame {...treasuryPreviewVideoData} />,
          content: <ClientConnectContent />
        }}
      </TwoColumnSectionWithImage>
      <ContactInfo type="commercial" />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default TreasuryPrime;
